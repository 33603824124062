/*
Copyright 2021 Venafi, Inc.
All Rights Reserved

This program is unpublished proprietary source code of Venafi, Inc.
Your use of this code is limited to those rights granted in the license between you and Venafi, Inc.

Author: Jared Lillywhite <jared.lillywhite@venafi.com>
 */
import {
  sharedState,
  rxjs,
  PendoState,
  SharedState,
  FeatureFlagsContainer,
  AuthenticationState,
  ProductEntitlementInformation,
  Capability,
  Region,
} from '@vaas-ui-staging/shared-state';
import { registerMicroApps, RegistrableApp, start } from 'qiankun';
import { triggerAppChange } from 'single-spa';
import './index.scss';
import { InputArguments, BaseInputArguments } from './types';
import { v4 as uuidv4 } from 'uuid';

const isLogoutKey = 'isLogoutKey';

function initializePendo(apiKey) {
  if (apiKey) {
    window.loadPendo && window.loadPendo(apiKey);
    let counter = 0;
    const waits = 10;
    const interval = setInterval(() => {
      if (window.pendo) {
        clearInterval(interval);
        sharedState.setIsPendoLoaded(true);
      } else {
        counter++;
        if (counter >= waits) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }
}

Promise.all([
  fetch('/single-spa-root-config/baseEnvironment.json')
    .then(response => {
      if (response.ok) {
        return response.json() as BaseInputArguments;
      } else {
        return undefined;
      }
    })
    .catch(() => undefined),
  fetch('/single-spa-root-config/environment.json').then(
    response => response.json() as InputArguments,
  ),
]).then(([baseInputArgs, inputArgs]) => {
  function getEntry(srcEntry) {
    const uiHost = inputArgs.uiHost || '';
    if (uiHost.startsWith('localhost')) {
      return `${srcEntry}`;
    }
    return `//${uiHost}${srcEntry}`;
  }

  if (baseInputArgs && baseInputArgs.apiBaseUrl) {
    inputArgs.apiBaseUrl = baseInputArgs.apiBaseUrl;
  }
  if (baseInputArgs && baseInputArgs.uiHost) {
    inputArgs.uiHost = baseInputArgs.uiHost;
  }
  if (baseInputArgs && baseInputArgs.region) {
    inputArgs.region = baseInputArgs.region;
  }

  //This is necessary for the special tenant prefixes to work, as it avoids using the default uiHost
  if (inputArgs.uiHost !== window.location.host) {
    inputArgs.uiHost = window.location.host;
  }

  let isLoggedIn = false;
  let selectedEntitlement;
  let selectedCapability;
  let webSocketUrl = '';

  if (inputArgs.apiBaseUrl && inputArgs.webSocketUrl) {
    const apiUrl = new URL(inputArgs.apiBaseUrl);
    let websocketProtocol = 'wss';
    if (apiUrl.hostname === 'localhost') {
      websocketProtocol = 'ws';
    }
    webSocketUrl =
      `${websocketProtocol}://` + apiUrl.host + inputArgs.webSocketUrl;
  } else {
    webSocketUrl = '';
  }

  const region = inputArgs.region || ('us' as Region); // default to Region.US
  const baseUrl = inputArgs.apiBaseUrl || '';
  const enrollmentUrl = inputArgs.uiEnrollmentUrl || '';
  const directlyLoadedAssets = [
    'https://www.google.com/recaptcha/api.js',
    'https://recaptcha.net/',
    'https://cdn.analytics.venafi.com/agent/static/',
    'cloudcdn.analytics.venafi.com',
  ] as const;
  // add every new authenticated sub-application micro service in this list
  const authenticatedMicroServices = [
    '/edge-management/',
    '/certificate-issuance/',
    '/issuance/',
    '/platform-settings/',
    '/vca-management/',
    '/vsatellites',
    '/app-auth/',
    '/t/',
    '/codesign/',
    '/global-config/',
    '/playground/',
    '/clusters-inventory',
    '/applications',
    '/credential-manager/',
    '/certificates',
    '/workflows/',
    '/cloud-providers/',
    '/vcp/',
  ] as const;
  const unauthenticatedMicroServices = ['/enrollment', '/playground/'] as const;
  const websocketClientId = uuidv4();
  sharedState.setRegion(region);
  sharedState.setBaseUrl(baseUrl); //this url is passed to the shared state and used by microfrontends to call the api.
  sharedState.setEnrollmentUrl(enrollmentUrl); //this url is passed to the shared state and used by authentication microfrontend, because we have different urls for production and development.
  sharedState.setWebSocketClientId(websocketClientId);
  sharedState.setWebSocketUrl(
    (webSocketUrl.endsWith('/') ? webSocketUrl : webSocketUrl + '/') +
      websocketClientId,
  ); //Add the client id to the base url. This url is passed to the shared state and used by microfrontends to check for push notifications

  const captchaKey = inputArgs.captchaKey || '';
  sharedState.setCaptchaKey(captchaKey);

  sharedState.authenticationState$.subscribe(
    (authenticationState: AuthenticationState) => {
      //Change active apps when the authentication state changes.
      isLoggedIn =
        !!authenticationState?.sessionToken &&
        !authenticationState?.pending &&
        sessionStorage.getItem(isLogoutKey) !== 'true';
      triggerAppChange();
    },
  );

  sharedState.selectedEntitlement$.subscribe(
    (entitlement: ProductEntitlementInformation | undefined) => {
      //Change active apps when the selected entitlement changes.
      selectedEntitlement = entitlement;
      triggerAppChange();
    },
  );

  sharedState.selectedCapability$.subscribe((capability: Capability | '') => {
    //Change active apps when the selected capability changes.
    selectedCapability = capability;
    triggerAppChange();
  });

  sharedState.pendoState$
    .pipe(
      rxjs.filter(
        pendoState =>
          pendoState &&
          pendoState.isLoaded &&
          !pendoState.isInitialized &&
          !!pendoState.visitor &&
          !!pendoState.account,
      ),
    )
    .subscribe((pendoState: PendoState) => {
      window.pendo &&
        window.pendo.initialize({
          visitor: { ...pendoState.visitor },
          account: { ...pendoState.account },
        });
      sharedState.setIsPendoInitialized(true);
    });
  initializePendo(inputArgs.pendoApiKey);

  const isCertificateIssuanceDefaultApp = () =>
    selectedEntitlement &&
    selectedEntitlement.name === 'OUTAGE_DETECTION' &&
    selectedCapability === '';

  const isVCAManagementDefaultApp = () =>
    selectedEntitlement &&
    selectedEntitlement.name === 'OUTAGE_DETECTION' &&
    selectedCapability === ('DISTRIBUTED_ISSUER' as Capability);

  const microApps: RegistrableApp<{ sharedState: SharedState }>[] = [];

  inputArgs.notificationsUrl &&
    microApps.push({
      name: 'user-notifications',
      entry: getEntry(inputArgs.notificationsUrl),
      container: '#notifications',
      activeRule: () => true,
      props: {
        sharedState,
      },
    });

  inputArgs.navigationUrl &&
    microApps.push({
      name: 'navigation',
      entry: getEntry(inputArgs.navigationUrl),
      container: '#nav',
      activeRule: () => {
        return (
          isLoggedIn &&
          //this line below is added so that navigation MFE does not
          //mount when playground MFE is mounted.
          location.pathname.indexOf('/playground') === -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.edgeManagementUrl &&
    microApps.push({
      name: 'edge-management',
      entry: getEntry(inputArgs.edgeManagementUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn && location.pathname.indexOf('/edge-management/') !== -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.vsatellitesUrl &&
    microApps.push({
      name: 'vsatellites',
      entry: getEntry(inputArgs.vsatellitesUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.startsWith('/vsatellites');
      },
      props: {
        sharedState,
      },
    });

  inputArgs.issuanceUrl &&
    microApps.push({
      name: 'issuance',
      entry: getEntry(inputArgs.issuanceUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.indexOf('/issuance/') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.applicationsUrl &&
    microApps.push({
      name: 'applications',
      entry: getEntry(inputArgs.applicationsUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.startsWith('/applications');
      },
      props: {
        sharedState,
      },
    });

  inputArgs.certificateIssuanceUrl &&
    microApps.push({
      name: 'certificate-issuance',
      entry: getEntry(inputArgs.certificateIssuanceUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn &&
          // This is needed to prevent mounting the root MFE for SSO/oauth requests
          // resulting in a brief 404 error before the correct routing happen.
          !location.pathname.startsWith('/oauth2') &&
          (location.pathname.indexOf('/certificate-issuance/') !== -1 ||
            (isCertificateIssuanceDefaultApp() &&
              authenticatedMicroServices.every(
                service => location.pathname.indexOf(service) === -1,
              )))
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.platformSettingsUrl &&
    microApps.push({
      name: 'platform-settings',
      entry: getEntry(inputArgs.platformSettingsUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn && location.pathname.indexOf('/platform-settings/') !== -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.vcaManagementUrl &&
    microApps.push({
      name: 'vca-management',
      entry: getEntry(inputArgs.vcaManagementUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn &&
          (location.pathname.indexOf('/vca-management/') !== -1 ||
            (isVCAManagementDefaultApp() &&
              authenticatedMicroServices.every(
                service => location.pathname.indexOf(service) === -1,
              )))
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.appAuthUrl &&
    microApps.push({
      name: 'app-auth',
      entry: getEntry(inputArgs.appAuthUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.indexOf('/app-auth/') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.authenticationUrl &&
    microApps.push({
      name: 'authentication',
      entry: getEntry(inputArgs.authenticationUrl),
      container: '#sub-application-container',
      activeRule: () => {
        return (
          (!isLoggedIn &&
            unauthenticatedMicroServices.every(
              service => location.pathname.indexOf(service) === -1,
            )) ||
          (isLoggedIn && location.pathname.startsWith('/oauth2'))
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.enrollmentUrl &&
    microApps.push({
      name: 'enrollment',
      entry: getEntry(inputArgs.enrollmentUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return !isLoggedIn && location.pathname.indexOf('/enrollment') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.playgroundUrl &&
    microApps.push({
      name: 'playground',
      entry: getEntry(inputArgs.playgroundUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return location.pathname.indexOf('/playground') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.serviceAccountsUrl &&
    microApps.push({
      name: 'service-accounts',
      entry: getEntry(inputArgs.serviceAccountsUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.indexOf('/t/') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.codesignUrl &&
    microApps.push({
      name: 'codesign',
      entry: getEntry(inputArgs.codesignUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.indexOf('/codesign/') !== -1;
      },
      props: {
        sharedState,
      },
    });

  inputArgs.globalConfigUrl &&
    microApps.push({
      name: 'global-config',
      entry: getEntry(inputArgs.globalConfigUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn && location.pathname.indexOf('/global-config/') !== -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.clustersInventoryUrl &&
    microApps.push({
      name: 'clusters-inventory',
      entry: getEntry(inputArgs.clustersInventoryUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn && location.pathname.indexOf('/clusters-inventory') !== -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.credentialManagerUrl &&
    microApps.push({
      name: 'credential-manager',
      entry: getEntry(inputArgs.credentialManagerUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return (
          isLoggedIn && location.pathname.indexOf('/credential-manager/') !== -1
        );
      },
      props: {
        sharedState,
      },
    });

  inputArgs.certificateInventoryUrl &&
    microApps.push({
      name: 'certificate-inventory',
      entry: getEntry(inputArgs.certificateInventoryUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.startsWith('/certificates');
      },
      props: {
        sharedState,
      },
    });

  inputArgs.workflowsUrl &&
    microApps.push({
      name: 'workflows',
      entry: getEntry(inputArgs.workflowsUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.startsWith('/workflows/');
      },
      props: {
        sharedState,
      },
    });

  inputArgs.cloudProvidersUrl &&
    microApps.push({
      name: 'cloud-providers',
      entry: getEntry(inputArgs.cloudProvidersUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.startsWith('/cloud-providers/');
      },
      props: {
        sharedState,
      },
    });

  inputArgs.vcpUrl &&
    microApps.push({
      name: 'vcp',
      entry: getEntry(inputArgs.vcpUrl),
      container: '#sub-application-container',
      activeRule: location => {
        return isLoggedIn && location.pathname.indexOf('/vcp') !== -1;
      },
      props: {
        sharedState,
      },
    });

  registerMicroApps(microApps);

  start({
    prefetch: true,
    sandbox: { experimentalStyleIsolation: true },
    singular: false, //the "singular" argument determines whether multiple microfrontends can be active at the same time. Since the navbar is always active when the user is logged in, this is set to false.
    excludeAssetFilter: url => {
      //qiankun intercepts most calls to load external assets and loads them using `fetch` so that it can maintain the sandbox around separate applications. Assets that pass this filter are loaded without sandboxing
      return (
        directlyLoadedAssets.filter(asset => url.indexOf(asset) !== -1).length >
        0
      );
    },
  });
});
